import {useMemo, useState} from 'react';
import cn from 'classnames';

import Toggle from '@/components/shared/Toggle/Toggle';
import SvgCancel from '@/components/brochureV2/icons/modules/Cancel';
import SvgGlobal from '@/components/brochureV2/icons/spot/Global';
import SvgNavArrowDown from '@/components/brochureV2/icons/modules/NavArrowDown';
import SvgCheckmark from '@/components/brochureV2/icons/Checkmark';
import {AlignmentEnum, ColorSchemeEnum} from '@/enums';
import {sectionSchemeClasses} from '@/stylesheets/brochureV2/colorSchemes';
import {useKeyPress} from '@/hooks/useKeyPress';
import {useOnClickOutside} from '@/hooks/useOnClickOutside';
import Anchor from '@/components/shared/Anchor/Anchor';
import {useSiteData} from '@/hooks/useSiteData';
import {useTranslations} from '@/hooks/useTranslations';
import type {Region} from '@data/types';

import {linkColor, filterRegions} from '../utils';

import {getOrderedRegions} from './utils';
import type {RegionSelectorItem} from './types';

interface RegionSelectorProps {
  alignment?: AlignmentEnum;
  colorScheme?: ColorSchemeEnum;
  availableHreflangs?: Set<string>;
  regionSelectorLinks?: Set<RegionSelectorItem>;
}

/**
 * @deprecated
 */
export default function RegionSelector({
  colorScheme,
  alignment,
  availableHreflangs = new Set(),
  regionSelectorLinks,
}: RegionSelectorProps) {
  const {t} = useTranslations();

  let {regions} = useSiteData();
  regions = filterRegions(regions);

  const currentAlignment = alignment || AlignmentEnum.Left;
  const isDark = colorScheme === ColorSchemeEnum.Dark;
  const [isActive, setIsActive] = useState(false);

  const fillColor = isDark ? 'fill-white' : 'fill-black';
  const bgColor = isDark ? 'bg-black text-white' : 'bg-white text-black';
  const boxShadow = isDark ? 'shadow-soft-dark-2xl' : 'shadow-soft-2xl';

  const selectedRegion = useMemo(() => {
    return regions?.find((region) => region.isActive) as RegionSelectorItem;
  }, [regions]);

  const selectors: Region[] = useMemo(
    () =>
      (regions
        ?.filter(({selector}) => selector)
        .map((region) => ({
          label: region.label,
          href: region.href,
          hreflang: region.hreflang,
          lang: region.lang,
          isActive: region.isActive,
        })) || []) as Region[],
    [regions],
  );

  const orderedRegions = useMemo(
    () =>
      getOrderedRegions(
        selectors,
        selectedRegion,
        regionSelectorLinks,
        availableHreflangs,
      ),
    [selectors, regionSelectorLinks, selectedRegion, availableHreflangs],
  );

  useOnClickOutside(() => setIsActive(false));
  useKeyPress('Escape', () => setIsActive(false));

  const toggleButtonMarkup = (buttonProps?: any) => {
    return (
      <button
        {...buttonProps}
        className={cn(
          'bg-transparent mx-auto md:inline-block py-3',
          colorScheme ? linkColor(colorScheme) : '',
        )}
        onClick={() => setIsActive(!isActive)}
        type="button"
        data-component-name={`region-selector-${isActive ? 'close' : 'open'}`}
        tabIndex={0}
        aria-label={`${t('global:ariaLabels.footer.regionsNav')}. ${t(
          'global:ariaLabels.current',
        )}: ${selectedRegion?.label}`}
      >
        <span className="inline-flex items-center">
          <SvgGlobal className={cn('mr-1 h-4 w-4', fillColor)} />
          <span className="break-keep">{selectedRegion?.label}</span>
          <SvgNavArrowDown
            className={cn('ml-2 h-3 w-3', fillColor, {'rotate-180': isActive})}
          />
        </span>
      </button>
    );
  };

  const navListMarkup = (
    <ul
      className="columns-2 px-5 py-8 md:px-14 md:py-10"
      data-component-name="region-nav"
      id="region-nav"
    >
      {orderedRegions?.map(
        (region, idx) =>
          region && (
            <li
              key={region.label}
              className={cn(
                'flex',
                colorScheme ? sectionSchemeClasses[colorScheme] : '',
              )}
            >
              <Anchor
                className={cn('flex hover:underline', {
                  'text-white': isDark,
                  'text-black': !isDark,
                })}
                href={region?.href || ''}
                lang={
                  region?.href === selectedRegion?.href
                    ? undefined
                    : region.lang
                }
                data-component-name={`${region.lang}-${idx}`}
                aria-current={region.isActive}
              >
                {region.label}
                {region.isActive && <SvgCheckmark className="ml-2 w-3" />}
              </Anchor>
            </li>
          ),
      )}
    </ul>
  );

  const desktopToggleMarkup = (
    <div
      className="relative hidden sm:block"
      data-click-outside="dismiss"
      data-component-name="regions"
    >
      {toggleButtonMarkup({
        'aria-controls': 'regionSelector',
        'aria-expanded': isActive,
        'aria-haspopup': 'true',
      })}
      <Toggle
        className={cn(
          'w-128 absolute bottom-[calc(100%+5em)] z-50 rounded-lg leading-8',
          bgColor,
          boxShadow,
          {
            'inset-x-0': currentAlignment === AlignmentEnum.Left,
            'right-0': currentAlignment === AlignmentEnum.Right,
          },
        )}
        hasAnimation={false}
        isActive={isActive}
        tag="div"
        toggleId="regionSelector"
        data-component-name="region-selector"
      >
        {navListMarkup}
      </Toggle>
    </div>
  );

  const mobileDrawerMarkup = (
    <div
      className="relative block sm:hidden"
      data-click-outside="dismiss"
      data-component-name="regions"
    >
      <div
        aria-hidden
        className={cn(
          'fixed top-0 left-0 z-50 h-full w-full text-[1.1428571429em] will-change-auto',
          'transition-opacity motion-reduce:transition-none',
          isDark ? 'bg-black' : 'bg-white',
          isActive ? 'pointer-events-auto' : 'pointer-events-none',
          isActive
            ? cn({
                'opacity-70': isDark,
                'opacity-90': !isDark,
              })
            : 'opacity-0',
        )}
        onClick={() => setIsActive(false)}
      />
      {toggleButtonMarkup()}
      <Toggle
        aria-label={t('global:ariaLabels.footer.regionsNav')}
        aria-modal="true"
        className={cn(
          'webkit-overflow-scrolling-touch',
          'fixed overflow-hidden z-[999] box-content will-change-auto',
          'w-full inset-0 mt-16 rounded-t-lg leading-[3rem]',
          'duration-300 ease-in-out motion-reduce:animation-none motion-reduce:transition-none',
          bgColor,
          boxShadow,
          {
            'visible translate-y-0': isActive,
            'invisible translate-y-full': !isActive,
          },
        )}
        hasAnimation
        isActive={isActive}
        role="dialog"
        toggleId="mobileRegionSelector"
      >
        <div className="border-shade-30 border-b">
          <div className="flex items-center justify-between px-5 py-4">
            <h3 className="text-lg font-medium">
              {t('footer:countrySelectHeading')}
            </h3>
            <button
              className="h-[1.2em] w-[1.2em] fill-current"
              onClick={() => setIsActive(false)}
              type="button"
              data-component-name="region-selector-close"
            >
              <SvgCancel
                aria-label={t('global:ariaLabels.footer.regionsClose')}
                className={cn('h-[1.2em] w-[1.2em] fill-current', fillColor)}
              />
            </button>
          </div>
        </div>

        <div className="h-full overflow-y-scroll pb-4">{navListMarkup}</div>
      </Toggle>
    </div>
  );

  return (
    <>
      {desktopToggleMarkup}
      {mobileDrawerMarkup}
    </>
  );
}
