import {cva} from 'class-variance-authority';

export const buttonContainerStyles = cva('flex gap-y-sm flex-wrap', {
  variants: {
    size: {
      large: 'gap-x-sm',
      small: 'gap-x-2',
    },
    center: {
      true: 'justify-center',
      false: 'justify-start',
    },
    centralItems: {
      true: 'items-center	',
      false: '',
    },
  },
  defaultVariants: {
    center: false,
  },
});

export const disclaimerStyles = cva('pt-sm', {
  variants: {
    center: {
      true: 'text-center',
      false: 'text-left',
    },
    mode: {
      light: 'text-black',
      dark: 'text-white',
    },
  },
  defaultVariants: {
    center: false,
    mode: 'light',
  },
});

export const promoBannerStyles = cva('mt-7 pb-2', {
  variants: {
    center: {
      true: 'text-center',
      false: 'text-left',
    },
  },
});
