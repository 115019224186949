/* eslint-disable no-console */
import type {MutableRefObject} from 'react';
import {useRef, useEffect} from 'react';

import {SHOULD_LOG} from '@/constants';

// This should be false in prod
//  written as a ternary to allow for dead code elimination in production
const debugLog = SHOULD_LOG
  ? (stack: string, renderCount: number) => {
      console.groupCollapsed(
        `%c[useIntersectionObserver] 👀 DO NOT IGNORE 👀 (Render #${renderCount}) If you see this log spam the console, expand to investigate why...`,
        'font-weight: bold;',
      );
      console.log(
        'useIntersectionObserver params should be memoized to avoid unnecessary observer re-creation.',
      );
      console.log(
        'Check the following stack trace to see where you may need to add some memoization (eg. useCallback, useMemo):',
      );
      console.log(stack);
      console.groupEnd();
    }
  : null;

const useIntersectionObserver = (
  refsToObserve: MutableRefObject<HTMLElement[] | HTMLElement | null> | null,
  observerCallBack: IntersectionObserverCallback,
  options?: IntersectionObserverInit | (() => IntersectionObserverInit),
) => {
  const stack = SHOULD_LOG ? new Error().stack : '';
  const renderCount = useRef(0);

  useEffect(() => {
    if (!refsToObserve) return;

    if (SHOULD_LOG) {
      // clear renderCount periodically so we only log to console when it's actually a problem
      const interval = setInterval(() => {
        renderCount.current = 0;
      }, 2000);
      return () => clearInterval(interval);
    }
  }, [refsToObserve]);

  useEffect(() => {
    if (!refsToObserve) return;

    if (renderCount.current++ >= 10) {
      debugLog?.(stack!, renderCount.current);
    }
    const returnedOptions = typeof options === 'function' ? options() : options;

    const observer = new IntersectionObserver(
      observerCallBack,
      returnedOptions,
    );

    if (Array.isArray(refsToObserve.current)) {
      const returnedRefs = refsToObserve.current as HTMLElement[];
      returnedRefs.forEach((elementRef: HTMLElement) => {
        observer.observe(elementRef);
      });
    } else if (refsToObserve.current) {
      const returnedRef = refsToObserve.current as HTMLElement;
      observer.observe(returnedRef);
    }

    return () => observer.disconnect();
  }, [observerCallBack, options, refsToObserve, stack]);
};

export default useIntersectionObserver;
