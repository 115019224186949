import {useEffect, useState} from 'react';

export const useMediaQuery = (query: string) => {
  const [matches, setMatches] = useState<boolean | null>(null);

  useEffect(() => {
    const matchMedia = window.matchMedia(query);

    const listener = () => setMatches(matchMedia.matches);

    // Triggered at the first client-side load and if query changes
    listener();

    // Listen matchMedia
    // For Safari < 14
    if (matchMedia.addListener) {
      matchMedia.addListener(listener);
    } else {
      matchMedia.addEventListener('change', listener);
    }

    return () => {
      if (matchMedia.removeListener) {
        matchMedia.removeListener(listener);
      } else {
        matchMedia.removeEventListener('change', listener);
      }
    };
  }, [query]);

  return matches;
};
