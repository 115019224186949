import type {I18NOptions} from '@/types';

import {linkpopUrl, isShopPayAvailable} from '../utils';

export default function products({t, site, localizePath}: I18NOptions) {
  const shopEnabled = !['pt-BR', 'en-IN'].includes(site.locale);
  const shouldShowPlusLink = [
    'fr-CA',
    'sv',
    'ko',
    'vi',
    'zh-HK',
    'en-HK',
    'zh-TW',
  ].includes(site.locale);

  const navItems = [];

  if (shopEnabled) {
    navItems.push({
      content: t('footer:shop'),
      url: 'https://shop.app',
    });
  }

  if (isShopPayAvailable(site)) {
    navItems.push({
      content: t('footer:shopPay'),
      url: localizePath('/shop-pay'),
    });
  }

  if (site.features.includes('plus') || shouldShowPlusLink) {
    navItems.push({
      content: t('footer:shopifyPlus'),
      url: localizePath('/plus'),
    });
  }

  if (site.features.includes('fulfillment')) {
    navItems.push({
      content: t('footer:sfn'),
      url: localizePath('/fulfillment'),
    });
  }

  navItems.push({
    content: t('footer:linkpop'),
    url: linkpopUrl(site),
  });

  navItems.push({
    content: t('footer:shopifyForEnterprise'),
    url: localizePath('/enterprise'),
  });

  return {
    content: t('footer:products'),
    navItems,
  };
}
