import {FLAG_US_DESKTOP_APP_CREDIT} from '@/flags';

import {useSiteData} from './useSiteData';
import {useFlag} from './useFlag';

export const useAcquisitionIncentive = () => {
  const active = useFlag(FLAG_US_DESKTOP_APP_CREDIT);

  const {getUrl} = useSiteData();

  return {
    active,
    termsLink: getUrl('/app-credit/terms-and-conditions'),
  };
};
