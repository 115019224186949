import cn from 'classnames';
import type {ForwardedRef, HTMLAttributes} from 'react';
import {forwardRef} from 'react';
import {normalizeName} from '@shopify/dux';

import {SectionErrorBoundary} from '@/components/base/layouts/Section/SectionErrorBoundary';

type SectionType = React.AriaAttributes &
  HTMLAttributes<HTMLElement> & {
    component?: string;
    sectionName?: string;
    sectionIndex?: number;
    componentName?: string;
    errorBoundaryFullWidth?: boolean;
    navbarPadding?: boolean;
  };

/**
 * TODO @potench fixtures/yml use `component`, dux uses `componentName`, SectionRenderer uses `sectionName`
 *    We need to consolidate to "sectionName" everywhere (or `component`)
 *
 * TODO @potench import dux componentName util and normalize it here
 */

/**
 * @deprecated Please use `Section` from `@/components/base/layouts/Section/Section.tsx`
 */
const Section = forwardRef(
  (
    {
      sectionName,
      sectionIndex,
      componentName,
      component,
      className,
      children,
      id,
      style,
      errorBoundaryFullWidth = false,
      navbarPadding = false,
      ...props
    }: SectionType,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    const domAttrs = Object.fromEntries(
      Object.entries(props).filter(([key]) =>
        Boolean(key.match(/^(aria|role|data-)/g)),
      ),
    );

    const name = sectionName || component;
    const transformSectionName = normalizeName(name || '');
    const normalizeComponentName =
      normalizeName(componentName || component || '') || transformSectionName;

    return (
      <SectionErrorBoundary
        fullWidth={errorBoundaryFullWidth}
        navbarPadding={navbarPadding}
      >
        <section
          className={cn(className)}
          style={style}
          data-section-name={transformSectionName}
          data-section-index={sectionIndex}
          data-component-name={normalizeComponentName}
          data-viewable-component
          id={id}
          {...domAttrs}
          ref={ref}
        >
          {children}
        </section>
      </SectionErrorBoundary>
    );
  },
);

Section.displayName = 'Section';
export default Section;
