import type {SVGAttributes} from 'react';

const SvgSocialTwitter = (props: SVGAttributes<SVGElement>) => (
  <svg {...props} viewBox="0 0 19 19">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 9.5C19 14.7467 14.7467 19 9.5 19C4.25329 19 0 14.7467 0 9.5C0 4.25329 4.25329 0 9.5 0C14.7467 0 19 4.25329 19 9.5ZM8.18721 10.0675L3.64633 3.99603H7.14609L10.123 7.97639L13.8073 3.99603H14.8358L10.5825 8.59099L15.379 15.0039H11.8792L8.64689 10.6819L4.6462 15.0039H3.61771L8.18721 10.0675ZM6.76655 4.75353H5.15877L12.2586 14.2464H13.8663L6.76655 4.75353Z"
    ></path>
  </svg>
);

export default SvgSocialTwitter;
