import type {HTMLAttributeAnchorTarget, ReactNode} from 'react';
import {Children, isValidElement} from 'react';
import {useTranslation} from 'react-i18next';

export interface AriaLabelProps {
  external?: boolean;
  target?: HTMLAttributeAnchorTarget;
  ariaLabel?: string;
  children?: ReactNode;
}

function getTextFromChildren(children: React.ReactNode) {
  let text = '';
  Children.forEach(children, (child, index) => {
    if (!child) return;
    if (typeof child === 'string' || typeof child === 'number') {
      text += child.toString();
    } else if (isValidElement(child)) {
      text += `${index > 0 ? ' ' : ''}${getTextFromChildren(
        child.props.children,
      )}`;
    }
  });
  return text;
}

export default function useAriaLabel({
  external,
  target,
  ariaLabel,
  children,
}: AriaLabelProps): string | undefined {
  const {t} = useTranslation();

  if (!external && !ariaLabel && !target) {
    return undefined;
  }
  const externalText = external ? t('global:ariaLabels.link.external') : '';
  const newWindowText =
    target === '_blank' ? t('global:ariaLabels.link.newWindow') : '';
  const title = ariaLabel || getTextFromChildren(children);

  const label = t('global:ariaLabels.link.label', {
    external: externalText,
    title,
    newWindow: newWindowText,
  });
  return label;
}
