import {cva} from 'class-variance-authority';

export const buttonStyles = cva(
  [
    'inline-block self-center overflow-hidden max-w-full px-button-px py-button-py ring-inset',
    'rounded-button text-button-size font-button-font font-button-weight tracking-button-tracking transition-all duration-150',
    'focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-state-focus focus-visible:outline',
  ],
  {
    variants: {
      intent: {
        primary: '',
        secondary: 'hover:ring-1 disabled:hover-ring-0',
      },
      mode: {
        light: '',
        dark: '',
      },
      size: {
        small: '',
        large: 'md:px-button-lg-px md:py-button-lg-py md:text-button-lg-size',
      },
      border: {
        true: 'border-2',
        false: 'border-0',
      },
    },
    compoundVariants: [
      {
        intent: 'primary',
        mode: 'light',
        className: [
          'text-button-light-primary-text bg-button-light-primary-bg border-button-light-primary-border ring-button-light-primary-border',
          'hover:text-button-light-primary-text-hover hover:bg-button-light-primary-bg-hover hover:border-button-light-primary-border-hover hover:ring-button-light-primary-border-hover',
          'focus:text-button-light-primary-text-focus focus:bg-button-light-primary-bg-focus focus:border-button-light-primary-border-focus focus:ring-button-light-primary-border-focus',
          'active:text-button-light-primary-text-active active:bg-button-light-primary-bg-active active:border-button-light-primary-border-active active:ring',
          'disabled:text-button-light-primary-text-disabled disabled:bg-button-light-primary-bg-disabled disabled:border-button-light-primary-border-disabled disabled:ring-button-light-primary-border-disabled',
        ],
      },
      {
        intent: 'primary',
        mode: 'dark',
        className: [
          'text-button-dark-primary-text bg-button-dark-primary-bg border-button-dark-primary-border ring-button-dark-primary-border',
          'hover:text-button-dark-primary-text-hover hover:bg-button-dark-primary-bg-hover hover:border-button-dark-primary-border-hover hover:ring-button-dark-primary-border-hover',
          'focus:text-button-dark-primary-text-focus focus:bg-button-dark-primary-bg-focus focus:border-button-dark-primary-border-focus focus:ring-button-dark-primary-border-focus',
          'active:text-button-dark-primary-text-active active:bg-button-dark-primary-bg-active active:border-button-dark-primary-border-active active:ring-button-dark-primary-border-active',
          'disabled:text-button-dark-primary-text-disabled disabled:bg-button-dark-primary-bg-disabled disabled:border-button-dark-primary-border-disabled disabled:ring-button-dark-primary-border-disabled',
        ],
      },
      {
        intent: 'secondary',
        mode: 'light',
        className: [
          'text-button-light-secondary-text bg-button-light-secondary-bg border-button-light-secondary-border ring-button-light-secondary-border',
          'hover:text-button-light-secondary-text-hover hover:bg-button-light-secondary-bg-hover hover:border-button-light-secondary-border-hover hover:ring-button-light-secondary-border-hover',
          'focus:text-button-light-secondary-text-focus focus:bg-button-light-secondary-bg-focus focus:border-button-light-secondary-border-focus focus:ring-button-light-secondary-border-focus',
          'active:text-button-light-secondary-text-active active:bg-button-light-secondary-bg-active active:border-button-light-secondary-border-active active:ring-button-light-secondary-border-active',
          'disabled:text-button-light-secondary-text-disabled disabled:bg-button-light-secondary-bg-disabled disabled:border-button-light-secondary-border-disabled disabled:ring-button-light-secondary-border-disabled',
        ],
      },
      {
        intent: 'secondary',
        mode: 'dark',
        className: [
          'text-button-dark-secondary-text bg-button-dark-secondary-bg border-button-dark-secondary-border ring-current',
          'hover:text-button-dark-secondary-text-hover hover:bg-button-dark-secondary-bg-hover hover:border-button-dark-secondary-border-hover hover:ring-button-dark-secondary-border-hover',
          'focus:text-button-dark-secondary-text-focus focus:bg-button-dark-secondary-bg-focus focus:border-button-dark-secondary-border-focus focus:ring-button-dark-secondary-border-focus',
          'active:text-button-dark-secondary-text-active active:bg-button-dark-secondary-bg-active active:border-button-dark-secondary-border-active active:ring-button-dark-secondary-border-active',
          'disabled:text-button-dark-secondary-text-disabled disabled:bg-button-dark-secondary-bg-disabled disabled:border-button-dark-secondary-border-disabled disabled:ring-button-dark-secondary-border-disabled',
        ],
      },
    ],
    defaultVariants: {
      intent: 'primary',
      size: 'large',
      mode: 'light',
    },
  },
);
