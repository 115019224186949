import type {SVGAttributes} from 'react';

const SvgSocialTikTok = (props: SVGAttributes<SVGElement>) => (
  <svg viewBox="0 0 30 30" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30ZM17.9679 11.937C19.1423 12.7643 20.551 13.2076 21.9952 13.2041V10.3838C21.2138 10.3841 20.4497 10.1579 19.7979 9.73365C19.3398 9.44298 18.9467 9.06336 18.6427 8.61802C18.3387 8.17268 18.1302 7.67098 18.0299 7.1436C17.9873 6.90737 17.9669 6.66779 17.9689 6.42789H15.0829V17.7069C15.0829 18.2146 14.92 18.7094 14.6173 19.1206C14.3146 19.5317 13.8877 19.8382 13.3975 19.9962C12.9073 20.1543 12.3789 20.1558 11.8878 20.0006C11.3966 19.8454 10.9679 19.5414 10.6627 19.1321C10.3576 18.7227 10.1916 18.2289 10.1886 17.7211C10.1856 17.2134 10.3456 16.7177 10.6458 16.3048C10.946 15.8919 11.3711 15.583 11.8604 15.4221C12.3496 15.2612 12.878 15.2566 13.37 15.409V12.5068C12.2944 12.3595 11.1987 12.539 10.2295 13.0213C9.26031 13.5037 8.46383 14.2658 7.94658 15.2058C7.42933 16.1458 7.21597 17.2188 7.33504 18.2814C7.4541 19.344 7.89992 20.3455 8.61286 21.1519C9.3258 21.9583 10.2719 22.5312 11.3246 22.794C12.3772 23.0568 13.4863 22.997 14.5035 22.6225C15.5207 22.2479 16.3975 21.5766 17.0166 20.6983C17.6357 19.82 17.9676 18.7766 17.9679 17.7075V11.937Z"
    />
  </svg>
);

export default SvgSocialTikTok;
