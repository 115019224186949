import {cva} from 'class-variance-authority';
import cn from 'classnames';

import {AlignmentEnum} from '@/enums';
import Typography from '@/components/base/elements/Typography/Typography';
import {twMerge} from '@/stylesheets/twMerge';
import type {Mode} from '@/components/base/types';
import {usePaidTrialLengthPromotion} from '@/hooks/experiments/usePaidTrialLengthPromotion';
import {useSignupPaidTrialMessage} from '@/hooks/experiments/useSignupPaidTrialMessage';
import {useAcquisitionIncentive} from '@/hooks/useAcquisitionIncentive';
import {useSiteData} from '@/hooks/useSiteData';
import {useGmvRewardsAcquisitionIncentive} from '@/hooks/experiments/useGmvRewardsAcquisitionIncentive';

import {useTranslations} from '../Page/I18N';

const PAGES_WITH_INCENTIVE_AND_SIGNUP_BANNER = [
  'buy-button',
  'dropshipping',
  'manage',
  'market',
  'paypal',
  'sell-on-spotify',
  'sell',
];

const isGmvRewardsBannerVisible = (
  handle: string | undefined,
  gmvRewardsActive: boolean,
) => {
  return (
    gmvRewardsActive &&
    handle &&
    PAGES_WITH_INCENTIVE_AND_SIGNUP_BANNER.includes(handle)
  );
};
interface SignupPaidTrialPromoProps {
  mode?: Mode;
  className?: string;
  alignment?: AlignmentEnum;
  is1F?: boolean;
  signupFormNoEmail?: boolean;
}

const BaseStyles = cva(
  [
    'relative px-1 py-0.5 rounded align-middle leading-[1rem] md:w-5s2 box-decoration-clone',
  ],
  {
    variants: {
      mode: {
        light: 'text-black',
        dark: 'text-white',
      },
    },
  },
);

const HighlighterStyles = cva('', {
  variants: {
    mode: {
      light: 'text-black bg-[#075E3426]',
      dark: 'text-white bg-[#CFA1FC33]',
    },
  },
});

export default function SignupPaidTrialPromo({
  mode,
  className,
  alignment = AlignmentEnum.Center,
  is1F = false,
  signupFormNoEmail = false,
}: SignupPaidTrialPromoProps) {
  const {t} = useTranslations();
  const isExpSignupPaidTrialMessage = useSignupPaidTrialMessage();
  const {paidTrialMonths} = usePaidTrialLengthPromotion();
  const {active} = useAcquisitionIncentive();
  const {isTreatment: gmvRewardsActive} = useGmvRewardsAcquisitionIncentive();
  const {handle} = useSiteData();

  const ContainerStyles = cn('relative overflow-hidden', {
    'items-center': alignment === AlignmentEnum.Center,
  });

  return isExpSignupPaidTrialMessage ? (
    signupFormNoEmail ? (
      <div className={twMerge(ContainerStyles, className)}>
        <Typography
          size="body-sm"
          className={twMerge(
            BaseStyles({mode}),
            !active &&
              !isGmvRewardsBannerVisible(handle, gmvRewardsActive) &&
              HighlighterStyles({mode}),
          )}
          as="span"
        >
          {t('global:signup.signupPaidTrialPromo.disclaimerNoEmail', {
            count: paidTrialMonths,
          })}
        </Typography>
      </div>
    ) : (
      <div className={twMerge(ContainerStyles, className)}>
        <Typography
          size="body-sm"
          className={twMerge(
            BaseStyles({mode}),
            !active &&
              !isGmvRewardsBannerVisible(handle, gmvRewardsActive) &&
              HighlighterStyles({mode}),
          )}
          as="span"
        >
          {t('global:signup.signupPaidTrialPromo.default', {
            count: paidTrialMonths,
          })}
        </Typography>
        {is1F && (
          <Typography size="body-sm" className={BaseStyles({mode})} as="p">
            {t('global:signup.signupPaidTrialPromo.disclaimer')}
          </Typography>
        )}
      </div>
    )
  ) : null;
}
