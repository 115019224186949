import cn from 'classnames';
import type {ReactNode} from 'react';

import {twMerge} from '@/stylesheets/twMerge';
import Anchor from '@/components/shared/Anchor/Anchor';
import type {NavigationListItem} from '@/types';

export interface NavItem extends NavigationListItem {
  name?: string;
  isActive?: boolean;
  lang?: string;
  componentName?: string;
  onClick?: () => void;
  className?: string;
  navItems?: NavItem[];
}

interface NavListProps {
  after?: ReactNode | string;
  before?: ReactNode | string;
  className?: string;
  itemClass?: string;
  linkClass?: string;
  labelledBy?: string;
  navItems: NavItem[];
  componentName?: string;
}

export default function NavList({
  after,
  before,
  className = '',
  itemClass,
  linkClass,
  labelledBy,
  navItems,
  componentName,
}: NavListProps) {
  if (!navItems || navItems.length === 0) {
    return null;
  }

  return (
    <ul
      aria-labelledby={labelledBy}
      className={className}
      data-component-name={componentName}
    >
      {navItems?.map((navItem) => {
        let props;
        if (navItem.url || navItem?.onClick) {
          props = {
            href: navItem.url ? navItem.url : undefined,
            lang: navItem.lang,
            'data-component-name': navItem.name || navItem.componentName,
            className: twMerge(
              cn(linkClass, {
                'relative overflow-hidden after:absolute after:top-[-5px] after:right-[-5px] after:h-[10px] after:w-[10px] after:bg-current after:rotate-45 cursor-pointer':
                  navItem.isActive,
              }),
              navItem.className,
            ),
            onClick: navItem?.onClick,
          } as any;

          if (navItem.isExternal) {
            props.className = twMerge('is-external', props.className);
            props['aria-describedby'] = 'NewWindowExternalSite';
            props.rel = 'noopener noreferrer';
            props.target = '_blank';
          }
        }

        return (
          <li key={navItem.content} className={itemClass}>
            {props ? (
              <Anchor
                className="justify-center sell-tablet:justify-start pr-[1.2em] font-bold leading-normal flex items-center [&_svg]:ml-2 [&_svg]:translate-x-0 transition-all [&:hover_svg]:translate-x-[0.3em] [&:focus_svg]:translate-x-[0.3em]"
                {...props}
              >
                {before}
                {navItem.content}
                {after}
              </Anchor>
            ) : (
              <span>{navItem.content}</span>
            )}
          </li>
        );
      })}
    </ul>
  );
}
