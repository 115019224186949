import {ColorSchemeEnum} from '@/enums';

export enum ShopifyLogoColor {
  Black = 'black',
  DarkGreen = 'darkGreen',
  ShopifyGreen = 'shopifyGreen',
  White = 'white',
}

export enum ShopifyLogoTheme {
  Black = 'black',
  MonotoneGreen = 'monotoneGreen',
  ShopifyGreen = 'shopifyGreen',
  White = 'white',
  WhiteLogoTransparent = 'whiteLogoTransparent',
}

export interface ShopifyLogoProps {
  logoTheme: ShopifyLogoTheme;
  logoWithText: boolean;
  textColor: ShopifyLogoColor;
  className?: string;
  height?: string;
  componentName?: string;
}

// Convert section scheme into ShopifyLogoTheme
export const getLogoTheme = (sectionScheme: ColorSchemeEnum) =>
  sectionScheme === ColorSchemeEnum.Dark
    ? ShopifyLogoTheme.White
    : ShopifyLogoTheme.Black;

// Convert section scheme into ShopifyLogoColor
export const getLogoColor = (sectionScheme: ColorSchemeEnum) =>
  sectionScheme === ColorSchemeEnum.Dark
    ? ShopifyLogoColor.White
    : ShopifyLogoColor.Black;
