import cn from 'classnames';

import {ColorSchemeEnum} from '@/enums';
import ShopifyLogo from '@/components/base/elements/Logo/ShopifyLogo';
import {
  ShopifyLogoColor,
  ShopifyLogoTheme,
} from '@/components/base/elements/Logo/types';
import Section from '@/components/brochureV2/primitive/Section/Section';
import Anchor from '@/components/shared/Anchor/Anchor';
import type {NavItem} from '@/components/shared/NavList/NavList';
import NavList from '@/components/shared/NavList/NavList';
import SocialIcons from '@/components/shared/SocialIcons/SocialIcons';
import {
  PRIVACY_PREFERENCES_MODAL_URL_COMPONENT_NAME,
  PRIVACY_PREFERENCES_MODAL_URL_HASH_KEY,
} from '@/constants';
import {
  useCookieNoticeBanner,
  useFooterManageConsent,
} from '@/hooks/useCookieNoticeBanner';
import {kebab} from '@/utils/utils';
import {useTranslations} from '@/hooks/useTranslations';
import type {I18NOptions, LinkComponent, NavigationListItem} from '@/types';
import shopify from '@/hooks/navigation/footer/shopify';
import resources from '@/hooks/navigation/footer/resources';
import developers from '@/hooks/navigation/footer/developers';
import products from '@/hooks/navigation/footer/products';
import globalImpact from '@/hooks/navigation/footer/global-impact';
import solutions from '@/hooks/navigation/footer/solutions';
import {legalPrivacyPath, legalTermPath} from '@/hooks/navigation/utils';
import {UrlUtils} from '@/utils/UrlUtils';

import RegionSelector from './RegionSelector/RegionSelector';
import {linkColor, getSocialLinks as defaultSocialLinks} from './utils';
import type {FooterProps} from './types';

export {FooterProps};

export const defaultFooterLegalNav = ({
  t,
  site,
  localizePath,
}: I18NOptions): LinkComponent[] => {
  const urlUtils = new UrlUtils(site);
  const links: LinkComponent[] = [
    {text: t('footer:termsOfService'), url: legalTermPath(site)},
    {text: t('footer:privacyPolicy'), url: legalPrivacyPath(site)},
    // web client will hide if not applicable
    {text: t('footer:managePrivacy'), url: '#privacy'},
    {text: t('footer:sitemap'), url: localizePath('/sitemap')},
  ];

  if (site?.locale === 'ja') {
    links.unshift(
      {text: 'Shopify Japan 株式会社'},
      {text: '特定商取引法に基づく表記', url: '/jp/legal/commercial-law'},
    );
  }

  if (site?.locale.startsWith('en') || site.locale.startsWith('fr')) {
    links.push({
      text: t('footer:privacyChoices'),
      url: urlUtils.getUrl('/', 'privacy'),
    });
  }

  if (site?.domain === 'www.shopify.fr') {
    links.push({
      text: 'Mentions légales',
      url: localizePath('/legal/legal-notice'),
    });
  }

  if (site?.domain === 'www.shopify.de') {
    links.push({text: 'Impressum', url: localizePath('/legal/legal-notice')});
  }

  return links;
};

export const defaultFooterNav = (
  options: I18NOptions,
): NavigationListItem[] => {
  const navItems = [
    shopify(options),
    resources(options),
    developers(options),
    products(options),
  ];

  const noImpact = [
    'de',
    'es',
    'es-ES',
    'es-CO',
    'es-MX',
    'fr',
    'fr-CA',
    'it',
    'ja',
    'pt-BR',
    'de-BE',
    'da',
    'sv',
    'nl',
    'nl-BE',
    'fr-BE',
    'de-BE',
    'en-NO',
    'en-ID',
    'en-PH',
    'en-BE',
    'en-DK',
    'en-ZA',
    'be',
    'ko',
    'vi',
    'en-NG',
    'zh-CN',
    'zh-HK',
    'en-HK',
    'zh-TW',
  ];
  if (!noImpact.includes(options.site.locale)) {
    navItems.push(globalImpact(options));
  }

  navItems.push(solutions(options));
  return navItems;
};

/**
 * @deprecated
 */
export default function Footer({
  colorScheme,
  sectionName = 'footer',
  className,
  navItemClassName,
  navItemsBoxClassName,
  hideRegionSelector,
  navFn = defaultFooterNav,
  legalNavFn = defaultFooterLegalNav,
  socialLinksFn = defaultSocialLinks,
  availableHreflangs = new Set(),
  regionSelectorLinks,
}: FooterProps) {
  const translations = useTranslations();
  const subNavs = navFn(translations);
  const legalNav = legalNavFn(translations);
  const showFooterManageConsent = useFooterManageConsent();
  const {updateShowCookieNotice} = useCookieNoticeBanner();
  const legalNavFiltered = legalNav.filter((value) => {
    return (
      !value.url?.includes(PRIVACY_PREFERENCES_MODAL_URL_HASH_KEY) ||
      showFooterManageConsent
    );
  });
  const currentScheme = colorScheme || ColorSchemeEnum.Light;
  const isDark = currentScheme === ColorSchemeEnum.Dark;
  const footerTextColor = cn({
    'text-white': isDark,
    'text-black': !isDark,
  });
  const footerBGColor = cn({
    'bg-black': isDark,
    'bg-inherit': !isDark,
  });

  return (
    <footer
      className={cn(
        'relative flex flex-col font-shopifysans',
        footerBGColor,
        footerTextColor,
        className,
      )}
      data-component-name="footer"
      data-viewable-component
    >
      <Section
        className={cn(
          'container flex flex-col gap-16 py-16 my-0',
          'sm:justify-start sm:pb-16',
          'md:flex-row md:flex-nowrap md:justify-between md:pb-20 md:py-20 md:gap-20',
          'xl:w-full',
          footerBGColor,
          footerTextColor,
        )}
        sectionName={sectionName}
        sectionIndex={1}
      >
        <div className="min-w-[100px]">
          <ShopifyLogo
            logoTheme={
              currentScheme === ColorSchemeEnum.Dark
                ? ShopifyLogoTheme.White
                : ShopifyLogoTheme.Black
            }
            logoWithText={false}
            textColor={ShopifyLogoColor.White}
            className="h-[44px]"
          />
        </div>
        {subNavs.length > 0 && (
          <div className="md:block md:justify-end">
            <div
              className={cn(
                'flex flex-col flex-wrap gap-12 gap-x-4 md:gap-20 md:gap-x-16 sm:grid sm:max-h-fit sm:grid-cols-3 lg:grid-cols-4',
                {
                  'max-h-[120rem]': subNavs.length > 6,
                  'max-h-[92rem] sm:max-h-[75rem]': subNavs.length < 7,
                },
              )}
            >
              {subNavs.map(
                (subNav) =>
                  subNav &&
                  subNav.navItems &&
                  subNav.navItems.length > 0 && (
                    <div
                      key={subNav.content}
                      className="w-[calc(50%_-_1rem)] sm:w-fit"
                      data-component-name={kebab(subNav?.content as string)}
                    >
                      <h2 className={`text-base font-bold ${footerTextColor}`}>
                        {subNav.content}
                      </h2>
                      <ul className={cn('mt-4 md:mt-6', navItemsBoxClassName)}>
                        {subNav.navItems?.map((navItem) => (
                          <li key={navItem.content}>
                            <Anchor
                              className={cn(
                                'mt-2 block py-3 text-base font-normal hover:underline md:py-0.5',
                                linkColor(currentScheme),
                                navItemClassName,
                              )}
                              href={navItem.url ?? ''}
                              aria-label={navItem.content}
                              data-component-name={
                                navItem.content && kebab(navItem.content)
                              }
                            >
                              {navItem.content}
                            </Anchor>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ),
              )}
            </div>
          </div>
        )}
      </Section>
      <Section
        className={cn('border-t my-0 container max-sm:mx-0 xl:w-full', {
          'border-white border-opacity-20 bg-inherit': isDark,
          'border-black border-opacity-20 bg-shade-10 sm:bg-white': !isDark,
        })}
        sectionName={sectionName}
        sectionIndex={2}
      >
        <div
          className={cn(
            'flex flex-col items-center justify-center gap-4 py-8',
            'sm:items-start sm:gap-8 lg:flex-row lg:justify-between lg:gap-10',
            footerBGColor,
            footerTextColor,
          )}
        >
          <div className="mt-1 flex w-full flex-col items-center justify-center sm:flex-row sm:items-start sm:justify-start sm:gap-8 lg:gap-14">
            {hideRegionSelector ? null : (
              <RegionSelector
                colorScheme={currentScheme}
                availableHreflangs={availableHreflangs}
                regionSelectorLinks={regionSelectorLinks}
              />
            )}

            <NavList
              className="flex flex-col items-center flex-wrap sm:flex-row sm:items-start gap-x-8 md:gap-x-10"
              componentName="bottom-nav"
              navItems={
                legalNavFiltered.map(({text, url, name}) => ({
                  content: text,
                  className:
                    url === PRIVACY_PREFERENCES_MODAL_URL_HASH_KEY
                      ? 'cursor-pointer'
                      : '',
                  url:
                    url === PRIVACY_PREFERENCES_MODAL_URL_HASH_KEY ? null : url,
                  name:
                    url === PRIVACY_PREFERENCES_MODAL_URL_HASH_KEY
                      ? PRIVACY_PREFERENCES_MODAL_URL_COMPONENT_NAME
                      : name || kebab(text || ''),
                  onClick:
                    url === PRIVACY_PREFERENCES_MODAL_URL_HASH_KEY
                      ? () => updateShowCookieNotice(true)
                      : undefined,
                })) as NavItem[]
              }
              itemClass={cn(
                'mt-2 block py-3 sm:mt-0',
                linkColor(currentScheme),
              )}
              linkClass="hover:underline"
            />
          </div>
          <SocialIcons
            socialLinksFn={socialLinksFn}
            listClass="flex gap-4 md:gap-6"
            linkClass={cn('block h-8 w-8', {
              'fill-white hover:fill-shade-30': isDark,
              'fill-black hover:fill-shade-70': !isDark,
            })}
          />
        </div>
      </Section>
    </footer>
  );
}
