import type {I18NOptions} from '@/types';

import {
  aboutPath,
  careersPath,
  pressUrl,
  partnersPath,
  legalPath,
} from '../utils';

export default function shopify(options: I18NOptions) {
  const {t, site, localizePath} = options;
  const navItems = [
    {
      content: t('footer:about'),
      url: aboutPath(options),
    },
  ];

  const careersAllowListLocales: string[] = [
    'en',
    'fr',
    'ja',
    'da',
    'sv',
    'nl',
    'ko',
    'vi',
    'en-BE',
    'nl-BE',
    'en-BE',
    'de-BE',
    'fr-BE',
    'en-DK',
    'en-NO',
    'en-ID',
    'en-PH',
    'en-ZA',
    'en-MY',
    'en-NG',
    'es',
    'es-CO',
    'es-MX',
    'zh-CN',
    'zh-HK',
    'en-HK',
    'zh-TW',
  ];

  if (careersAllowListLocales.includes(site.locale)) {
    navItems.push({
      content: t('footer:careers'),
      url: careersPath(options),
    });
  }

  navItems.push({
    content: t('footer:investors'),
    url: 'https://investors.shopify.com/home/default.aspx',
  });

  const pressAllowListLocales: string[] = [
    'en',
    'fr',
    'da',
    'sv',
    'nl',
    'ko',
    'vi',
    'en-BE',
    'nl-BE',
    'en-BE',
    'de-BE',
    'fr-BE',
    'en-DK',
    'en-NO',
    'en-ID',
    'en-PH',
    'en-ZA',
    'en-MY',
    'en-NG',
    'es',
    'es-CO',
    'es-MX',
    'zh-CN',
    'zh-HK',
    'en-HK',
    'zh-TW',
  ];

  if (pressAllowListLocales.includes(site.locale)) {
    navItems.push({
      content: t('footer:pressAndMedia'),
      url: pressUrl(options),
    });
  }

  navItems.push(
    {
      content: t('footer:partners'),
      url: partnersPath(site),
    },
    {
      content: t('footer:affiliates'),
      url: localizePath('/affiliates'),
    },
    {
      content: t('footer:legal'),
      url: legalPath(site),
    },
    {
      content: t('footer:serviceStatus'),
      url: 'https://www.shopifystatus.com/',
    },
  );

  return {
    content: t('footer:shopify'),
    navItems,
  };
}
