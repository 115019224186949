import type {SVGAttributes} from 'react';

import {twMerge} from '@/stylesheets/twMerge';

export default function SvgSocialEmail({
  className,
  ...restProps
}: SVGAttributes<SVGElement>) {
  return (
    <svg
      viewBox="0 0 30 30"
      className={twMerge('bg-black fill-white rounded-full', className)}
      {...restProps}
    >
      <path
        transform="translate(5, 5)"
        d="M20 7.924v8.862c0 0.982-0.804 1.786-1.786 1.786h-16.429c-0.982 0-1.786-0.804-1.786-1.786v-8.862c0.335 0.368 0.714 0.692 1.127 0.971 1.853 1.261 3.728 2.522 5.547 3.85 0.938 0.692 2.098 1.54 3.315 1.54h0.022c1.217 0 2.377-0.848 3.315-1.54 1.819-1.317 3.694-2.589 5.558-3.85 0.402-0.279 0.781-0.603 1.116-0.971zM20 4.643c0 1.25-0.926 2.377-1.908 3.058-1.741 1.205-3.493 2.411-5.223 3.627-0.725 0.502-1.953 1.529-2.857 1.529h-0.022c-0.904 0-2.132-1.027-2.857-1.529-1.73-1.217-3.482-2.422-5.212-3.627-0.792-0.536-1.92-1.797-1.92-2.813 0-1.094 0.592-2.031 1.786-2.031h16.429c0.971 0 1.786 0.804 1.786 1.786z"
      />
    </svg>
  );
}
