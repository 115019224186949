import Button from '@/components/base/elements/Button/Button';
import type {ButtonProps as BaseButtonProps} from '@/components/base/elements/Button/Button';
import Typography from '@/components/base/elements/Typography/Typography';
import SignupPaidTrialPromo from '@/components/shared/SignupPaidTrialPromo/SignupPaidTrialPromo';
import useSignup from '@/hooks/useSignup';

import {
  disclaimerStyles,
  buttonContainerStyles,
  promoBannerStyles,
} from './styles';

type ButtonPropsWithoutChildren = Omit<BaseButtonProps, 'children'>;

export interface ButtonProps extends ButtonPropsWithoutChildren {
  text?: string;
}
export interface ButtonGroupContentProps {
  buttons: ButtonProps[];
  disclaimerHtml?: React.ReactNode;
  displayPromoBanner?: boolean;
  isPillarPage?: boolean;
}
export interface ButtonGroupProps extends ButtonGroupContentProps {
  className?: string;
  center?: boolean;
  mode?: 'light' | 'dark';
  size?: 'small' | 'large';
}

export default function ButtonGroup({
  buttons,
  className,
  center,
  disclaimerHtml,
  mode = 'light',
  size = 'large',
  displayPromoBanner = false,
  isPillarPage = false,
}: ButtonGroupProps) {
  const {signupUrl} = useSignup();
  const componentName = 'button-group';

  const renderDisclaimer = () => {
    const hasSignupUrl = buttons?.some((item: any) => item.href === signupUrl);

    return displayPromoBanner ? (
      <SignupPaidTrialPromo
        mode={mode}
        className={isPillarPage ? 'w-52' : promoBannerStyles({center})}
        signupFormNoEmail={!hasSignupUrl}
      />
    ) : (
      disclaimerHtml && (
        <Typography
          size="body-sm"
          className={disclaimerStyles({center, mode})}
          as="p"
        >
          {disclaimerHtml}
        </Typography>
      )
    );
  };
  return (
    <div
      data-component-name={componentName}
      className={className}
      data-mode={mode}
    >
      <div
        className={buttonContainerStyles({
          size,
          center,
          centralItems: isPillarPage,
        })}
      >
        {buttons?.map(({href, text, ...props}, index) => (
          <Button
            key={href}
            href={href}
            mode={mode}
            size={size}
            intent={index === 0 ? 'primary' : 'secondary'}
            {...props}
          >
            {text}
          </Button>
        ))}
        {isPillarPage && renderDisclaimer()}
      </div>
      {!isPillarPage && renderDisclaimer()}
    </div>
  );
}
