import {useEffect} from 'react';

export const useOnClickOutside = (handler: (event: Event) => void) => {
  useEffect(() => {
    const listener = (event: Event) => {
      const elems = document.querySelectorAll("[data-click-outside='dismiss']");
      let skipDismissal = false;

      elems.forEach((elem) => {
        if (elem.contains(event.target as Node) || elem === event.target) {
          skipDismissal = true;
        }
      });

      if (!skipDismissal) handler(event);
    };

    document.addEventListener('click', listener);
    return () => {
      document.removeEventListener('click', listener);
    };
  }, [handler]);
};
