import type {AnchorHTMLAttributes} from 'react';

import useAriaLabel from '@/hooks/useAriaLabel';

type AnchorProps = AnchorHTMLAttributes<HTMLAnchorElement> & {
  componentName?: string;
};

export default function Anchor({componentName, ...props}: AnchorProps) {
  const isExternal = !!(
    props.href &&
    (props.href.startsWith('http://') || props.href.startsWith('https://'))
  );

  const ariaLabel = useAriaLabel({
    external: isExternal,
    children: props.children,
    ariaLabel: props['aria-label'],
    target: props.target,
  });

  return (
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    <a
      {...props}
      {...(componentName ? {'data-component-name': componentName} : {})}
      aria-label={ariaLabel}
    />
  );
}
