import type {I18NOptions} from '@/types';

export default function globalImpact({t, site, localizePath}: I18NOptions) {
  const climatePath = () => {
    return localizePath('/climate');
  };

  const socialImpactPath = () => {
    if (['en-DK', 'en-NO', 'en-ID', 'en-MY', 'en-PH'].includes(site.locale)) {
      return '/about/social-impact';
    }
    return localizePath('/about/social-impact');
  };

  const researchPath = () => {
    if (['en-DK', 'en-BE', 'en-ID', 'en-MY', 'en-PH'].includes(site.locale)) {
      return '/research/future-of-commerce';
    }
    return localizePath('/plus/commerce-trends');
  };

  const buildBlackUrl = () => {
    return 'https://www.shopify.com/1mbb';
  };

  const navItems = [
    {
      content: t('footer:sustainability'),
      url: climatePath(),
    },
    {
      content: t('footer:socialImpact'),
      url: socialImpactPath(),
    },
    {
      content: t('footer:buildBlack'),
      url: buildBlackUrl(),
    },
  ];

  if (!['SG', 'IN', 'BE', 'MY'].includes(site.countryCode)) {
    navItems.push({
      content: t('footer:buildNative'),
      url: 'https://buildnative.shop',
    });
  }

  if (
    ['en', 'en-BE', 'en-DK', 'en-ID', 'en-MY', 'en-PH'].includes(site.locale)
  ) {
    navItems.push({
      content: t('footer:research'),
      url: researchPath(),
    });
  }

  return {
    content: t('footer:globalImpact'),
    navItems,
  };
}
