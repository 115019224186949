import type {I18NOptions} from '@/types';

export default function developers({t}: I18NOptions) {
  return {
    content: t('footer:developers'),
    navItems: [
      {
        content: 'Shopify.dev',
        url: 'https://shopify.dev',
      },
      {
        content: t('footer:apiDocs'),
        url: 'https://shopify.dev/api',
      },
      {
        content: t('footer:devDegree'),
        url: 'https://devdegree.ca',
      },
    ],
  };
}
