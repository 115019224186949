import type {VariantMap} from '@/types';

import SocialFacebook from './SocialFacebook';
import SocialInstagram from './SocialInstagram';
import SocialLinkedin from './SocialLinkedin';
import SocialPinterest from './SocialPinterest';
import SocialSnapchat from './SocialSnapchat';
import SocialTikTok from './SocialTikTok';
import SocialTwitter from './SocialTwitter';
import SocialYoutube from './SocialYoutube';
import SocialEmail from './SocialEmail';

const socialIcons: VariantMap = {
  facebook: SocialFacebook,
  twitter: SocialTwitter,
  youtube: SocialYoutube,
  instagram: SocialInstagram,
  linkedin: SocialLinkedin,
  pinterest: SocialPinterest,
  snapchat: SocialSnapchat,
  tiktok: SocialTikTok,
  email: SocialEmail,
};

export default socialIcons;
