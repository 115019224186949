import type {SVGAttributes} from 'react';

const Checkmark = (props: SVGAttributes<SVGElement>) => (
  <svg
    viewBox="0 0 10 8"
    fill="none"
    aria-hidden="true"
    focusable="false"
    {...props}
  >
    <path
      d="M0.75 4.75L3 7.25L9.25 0.75"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Checkmark;
