import type {ReactNode} from 'react';

interface AnimationProps {
  className: string;
  hasAnimation: true;
}

interface NoAnimationProps {
  className?: string;
  hasAnimation: false;
}

interface ToggleProps extends React.HTMLProps<HTMLElement> {
  children: ReactNode;
  isActive?: boolean;
  tag?: keyof JSX.IntrinsicElements;
  toggleId: string;
}

export default function Toggle({
  children,
  className,
  isActive = false,
  hasAnimation = false,
  tag = 'div',
  toggleId,
  ...props
}: (ToggleProps & AnimationProps) | (ToggleProps & NoAnimationProps)) {
  const Wrapper = tag as any;
  const styleProps = hasAnimation
    ? {}
    : {style: {display: isActive ? 'block' : 'none'}};

  return (
    <Wrapper
      aria-hidden={!isActive}
      className={className}
      id={toggleId}
      {...props}
      {...styleProps}
    >
      {children}
    </Wrapper>
  );
}
