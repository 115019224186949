import type {I18NOptions, VariantMap} from '@/types';
import {useTranslations} from '@/hooks/useTranslations';

import Anchor from '../Anchor/Anchor';

import socialIcons from '.';

export interface SocialLink {
  name: string;
  text: string;
  url: string;
}

interface SocialIconsProps {
  listClass?: string;
  itemClass?: string;
  linkClass?: string;
  iconClass?: string;
  iconMap?: VariantMap;
  socialLinksFn: (options: I18NOptions) => SocialLink[];
}

export default function SocialIcons({
  listClass,
  itemClass,
  linkClass,
  iconClass,
  iconMap,
  socialLinksFn,
}: SocialIconsProps) {
  const translations = useTranslations();
  const socialLinks = socialLinksFn(translations);
  return (
    <ul className={listClass} data-component-name="social">
      {socialLinks.map(({name, text, url}) => {
        const Component = iconMap ? iconMap[name] : socialIcons[name];
        return (
          <li key={name} className={itemClass}>
            <Anchor
              className={linkClass}
              href={url}
              rel="me nofollow noopener noreferrer"
              target="_blank"
              data-component-name={`social-${name}`}
              aria-label={text}
            >
              <Component className={iconClass} />
            </Anchor>
          </li>
        );
      })}
    </ul>
  );
}
