/* eslint-disable camelcase */
// ~~~ Tailwind Class Matching ~~~~

export const heroBackgroundByTheme: {[key: string]: string} = {
  agave: 'bg-agave-30',
  aloe: 'bg-aloe-10',
  black: 'bg-black text-white',
  kale: 'bg-kale-90 text-white fill-white',
  lavender: 'bg-lavender-10',
  lime: 'bg-lime-10',
  // temporary for pos
  pos_dark:
    'bg-shade-90 text-shade-20 fill-shade-20 default:[&_h1]:text-white default:[&_h2]:text-white default:[&_h3]:text-white border-opacity-[15%]',
  pos_light:
    'bg-white text-shade-70 fill-shade-70 default:[&_h1]:text-shade-90 default:[&_h2]:text-shade-90 default:[&_h3]:text-shade-90 border-opacity-[15%]',
  pos_warm_gray:
    'bg-pos-warm-gray text-shade-70 fill-shade-70 default:[&_h1]:text-shade-90 default:[&_h2]:text-shade-90 default:[&_h3]:text-shade-90 border-opacity-[15%]',
  // temporary for /start demo
  shopify_green: 'bg-shopify-green-10',
  shopify_green_gradient:
    'bg-gradient-radial-to-br from-agave-30 to-jalapeño-30',
  warm_gray: 'bg-[#FAF9F8]',
  white: 'bg-white',
};

export const conversionBackgroundByTheme: {[key: string]: string} = {
  agave: 'bg-gradient-to-br from-agave-30 to-aloe-30',
  aloe: 'bg-gradient-to-br from-lavender-10 to-aloe-30',
  black: 'bg-black text-white fill-white',
  dark: 'bg-black text-white fill-white',
  kale: 'bg-kale-90 text-white fill-white',
  white: 'bg-white',
  lavender: 'bg-gradient-to-br from-agave-30 to-[#AB8EFF]',
  lime: 'bg-lime-10',
  // temporary for pos
  pos_dark:
    'bg-shade-90 text-shade-20 fill-shade-20 default:[&_h1]:text-white default:[&_h2]:text-white default:[&_h3]:text-white border-opacity-[15%]',
  pos_light:
    'bg-white text-shade-70 fill-shade-70 default:[&_h1]:text-shade-90 default:[&_h2]:text-shade-90 default:[&_h3]:text-shade-90 border-opacity-[15%]',
  pos_warm_gray:
    'bg-pos-warm-gray text-shade-70 fill-shade-70 default:[&_h1]:text-shade-90 default:[&_h2]:text-shade-90 default:[&_h3]:text-shade-90 border-opacity-[15%]',
  shopify_green: 'bg-gradient-to-br from-shopify-green-30 to-agave-30',
  shopify_green_gradient: 'bg-gradient-to-br from-jalapeño-30 to-aloe-30',
  shopify_purple_gradient:
    'bg-gradient-to-br from-mandarin-30 to-[#865CFF] from-15%',
  shopify_green_blog: 'bg-[#7BE986]',
  transparent: 'bg-transparent',
  deep_blue: 'bg-[#0139FD] text-white',
};

export const sectionSchemeClasses: {[key: string]: string} = {
  dark: 'bg-black text-white fill-white',
  cyan_light: 'bg-pos-cyan-light',
  gray: 'bg-[#F6F8FA]',
  kale: 'bg-kale-90 text-white fill-white',
  light: '',
  lime: 'bg-lime-10',
  // temporary for pos
  pos_dark:
    'bg-shade-90 text-shade-20 fill-shade-20 default:[&_h1]:text-white default:[&_h2]:text-white default:[&_h3]:text-white border-opacity-[15%]',
  pos_light:
    'bg-white text-shade-70 fill-shade-70 default:[&_h1]:text-shade-90 default:[&_h2]:text-shade-90 default:[&_h3]:text-shade-90 border-opacity-[15%]',
  pos_warm_gray:
    'bg-pos-warm-gray text-shade-70 fill-shade-70 default:[&_h1]:text-shade-90 default:[&_h2]:text-shade-90 default:[&_h3]:text-shade-90 border-opacity-[15%]',
  warm_gray: 'bg-[#FAF9F8]',
  white: 'bg-white',
};

export const pillSchemeClasses: {[key: string]: string} = {
  agave: 'bg-agave-10',
  aloe: 'bg-aloe-10',
  aloe_30: 'bg-aloe-30 text-shade-90',
  banana: 'bg-banana-10',
  black: 'bg-black text-white fill-white',
  dark: 'bg-black text-white fill-white',
  dragon_fruit: 'bg-dragonfruit-10',
  gray: 'bg-shade-10',
  kale: 'bg-kale-10',
  lavender: 'bg-lavender-10',
  light: '',
  lime: 'bg-lime-10',
  shopify_green: 'bg-shopify-green-10',
  warm_gray: 'bg-[#FAF9F8]',
  white: 'bg-white',
  shopify_plus_green: 'bg-lime-30',
};
