import {useState, useEffect} from 'react';

/*
from:
https://since1979.dev/respecting-prefers-reduced-motion-with-javascript-and-react/
Usage:
import useReducedMotion from "./useReducedMotion.js";
export default function App() {
  // Get the reduced motion state from our hook.
  const reducedMotion = useReducedMotion(false);
  return (
    <>
      // Set text according the reduced motion setting.
      <h1>{reducedMotion ? "Do not animate element" : "Animate element"}</h1>
      // Add a class based on the reduced motion setting.
      <div className={"mainClass " + (reducedMotion ? null : "animate")}></div>
    </>
  );
}
*/

const useReducedMotion = (defaultVal = true) => {
  // Local state to store the reduced motion setting.
  const [reducedMotion, setReducedMotion] = useState(defaultVal);

  // Callback for media query change events.
  function queryChangeHandler(event: any) {
    // Set the state to the value of the media query.
    setReducedMotion(event.target.matches);
  }

  useEffect(() => {
    // Grab the reduced motion media query,
    const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');

    if ((window as any).REDUCE_MOTION) {
      setReducedMotion(true);
    } else if (mediaQuery) {
      // Set the state to the value of the media query.
      setReducedMotion(mediaQuery.matches);
      // Listen for changes in the media query.
      mediaQuery?.addEventListener?.('change', queryChangeHandler);
      // Remove the event listener when the component unmounts.
      return () =>
        mediaQuery?.removeEventListener?.('change', queryChangeHandler);
    }
  }, []);

  return reducedMotion;
};

export default useReducedMotion;
