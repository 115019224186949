import {type VariantProps} from 'class-variance-authority';
import type {HTMLAttributeAnchorTarget} from 'react';

import {twMerge} from '@/stylesheets/twMerge';
import type {Mode} from '@/components/base/types';
import useAriaLabel from '@/hooks/useAriaLabel';

import {buttonStyles} from './styles';

export interface ButtonProps
  extends VariantProps<typeof buttonStyles>,
    React.AnchorHTMLAttributes<HTMLAnchorElement> {
  className?: string;
  children: React.ReactNode;
  componentName?: string;
  href: string;
  intent?: 'primary' | 'secondary';
  mode?: Mode;
  size?: 'small' | 'large';
  target?: HTMLAttributeAnchorTarget;
  external?: boolean;
  border?: boolean;
  'aria-label'?: string;
}

export default function Button({
  className = '',
  children,
  componentName = 'button',
  href,
  intent,
  mode,
  size,
  target,
  external = false,
  border = true,
  'aria-label': ariaLabelProp,
  ...props
}: ButtonProps) {
  const styles = buttonStyles({intent, mode, size, border});
  const externalTarget = external ? '_blank' : '';
  const targetText = target || externalTarget;
  const ariaLabel = useAriaLabel({
    external,
    target: targetText,
    ariaLabel: ariaLabelProp,
    children,
  });
  return (
    <a
      href={href}
      className={twMerge(styles, className)}
      data-component-name={componentName}
      data-mode={mode}
      target={targetText}
      {...(external ? {rel: 'noopener noreferrer'} : {})}
      {...(ariaLabel ? {'aria-label': ariaLabel} : {})}
      {...props}
    >
      {children}
    </a>
  );
}
