import {useEffect} from 'react';

export const useKeyPress = (
  key: string,
  handler: (event: KeyboardEvent) => void,
) => {
  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.key === key) handler(event);
    };

    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [key, handler]);
};
