import {ColorSchemeEnum} from '@/enums';
import type {Region} from '@data/types';
import type {I18NOptions} from '@/types';
import type {SocialLink} from '@/components/shared/SocialIcons/SocialIcons';

export const linkColor = (colorScheme: ColorSchemeEnum) => {
  return colorScheme === ColorSchemeEnum.Dark
    ? 'text-[#E0E0E0] hover:text-white'
    : 'text-shade-90 hover:text-black';
};

export const filterRegions = (regions: Region[] | undefined) => {
  return regions?.filter((item) => item.published);
};

export const getSocialLinks = (options: I18NOptions): SocialLink[] => {
  const {t, site} = options;
  const socials = [
    'facebook',
    'twitter',
    'youtube',
    'instagram',
    'tiktok',
    'linkedin',
    'pinterest',
  ];

  return socials.map((name: string): SocialLink => {
    return {
      name,
      text: t(`global:social.${name}.text`),
      url: t(`global:social.${name}.url`, {
        context: site.countryCode.toLowerCase(),
      }),
    };
  });
};
